@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

:root {
  --secondary--extraDark: #312257;
  --primary: #7549ea;
  font-family: "Poppins", sans-serif;
}
html,
body {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--secondary--extraDark);
}

.socials {
  background-color: var(--secondary--extraDark);
}
.socials ul {
  margin: 0;
}
.socials ul li {
  list-style-type: none;
  display: inline-block;
  margin: 15px 10px;
  vertical-align: middle;
}

.socials ul li a {
  font-size: 18px;
  color: #fff;
}

nav {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
}

nav ul li {
  list-style-type: none;
  display: inline-block;
}

nav img {
  width: 180px;
}

.main-title {
  font-size: clamp(2rem, 1.3333rem + 2.2222vw, 3rem);
  text-align: center;
}

.flex {
  display: flex;
}

.container {
  padding: 25px;
  max-width: 1200px;
  margin: 15px auto;
}

.row {
  display: flex;
}

.column {
  flex: 1;
}

.header-img {
  width: 100%;
  max-width: 400px;
  display: block;
  margin: auto;
}

.answer-label {
  color: var(--primary);
  font-size: 1.2rem;
  margin-bottom: 0;
}

.answer {
  color: var(--secondary--extraDark);
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 0;
}

.accordion-title {
  cursor: pointer;
}

.accordion-title svg {
  /* This is an odd bit of pixel-peeping but aligns the react-icons output well */
  vertical-align: -15%;
}

.accordion-body.open {
  display: block;
}
.accordion-body.closed {
  display: none;
}

.accordion-body {
  margin: 0;
  padding-left: 20px;
  text-align: left;
  color: var(--secondary--extraDark);
}

@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;
  }
}
